import React, { useState, useCallback } from "react";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { storeSectionUrl, storeSubNavbarName } from "../../../Redux/action"
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "../../CommanFunctions/commanFunctions";

const Header = (props) => {
    const selector = useSelector(state => state);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [theme, setTheme] = useState(1);

    const darkMode = useCallback((value) => {
        if (isEmpty(value) !== "") {
            sessionStorage.setItem("colorCode", value);
            setTheme(value);
            if (value === 0) {
                document.documentElement.style.setProperty('--theme-top-header-bg-color', "#222e45");
                document.documentElement.style.setProperty('--theme-top-header-text-color', "#ffffff");

                document.documentElement.style.setProperty('--theme-sidebar-text-color', "#f5f6f7");
                document.documentElement.style.setProperty('--theme-sidebar-bg-color', "#222e45");

                document.documentElement.style.setProperty('--theme-footer-title-color', "#ffffff");
                document.documentElement.style.setProperty('--theme-footer-text-color', "#f5f6f7");
                document.documentElement.style.setProperty('--theme-footer-bg-color', "#202b40");

                document.documentElement.style.setProperty('--theme-body-title-color', "#ffffff");
                document.documentElement.style.setProperty('--theme-body-sub-title-color', "#ffffff");
                document.documentElement.style.setProperty('--theme-body-text-color', "#f5f6f7");
                document.documentElement.style.setProperty('--theme-body-bg-color', "#192132");
                document.documentElement.style.setProperty('--theme-body-sub-bg-color', "#202b40");

                document.documentElement.style.setProperty('--theme-btn-primary-bg-color', "#222e45");
                document.documentElement.style.setProperty('--theme-btn-primary-text-color', "#ffffff");

                document.documentElement.style.setProperty('--theme-btn-secondary-bg-color', "#384766");
                document.documentElement.style.setProperty('--theme-btn-secondary-text-color', "ffff");
            } else {
                const publish_json = isEmpty(sessionStorage.getItem("color_json"));
                const publish = isEmpty(JSON.parse(publish_json)) !== "" ? JSON.parse(publish_json) : {};
                if (Object.keys(publish).length > 0) {
                    props.colorApi(publish)
                }
                const img = document.getElementsByTagName("img");
                if (img.length > 0) {
                    for (let c = 0; c < img.length; c++) {
                        document.getElementsByTagName("img")[c].style.filter = "none";
                    }
                    sessionStorage.setItem("imgFilter", 0);
                }
            }
        }
    }, [props]);

    const sidebarToggle = (event) => {
        sessionStorage.setItem("storeUrl", `/dashborad`);
        dispatch(storeSubNavbarName("dashborad"));
        dispatch(storeSectionUrl("dashborad"));
        if (window.location.pathname === `/dashborad`) {
            event.stopPropagation();
            event.preventDefault();
            navigate("/-");
        } else {
            navigate("/")
        }
    }

    return (
        <div id="MainHeader">
            <div className="HeaderNavbar">
                <div className="Pos_text">
                    {props.fixsidebar === false ?
                        <>
                            <div className="sidebarControl" onClick={() => { props.fixsidebarclick(); }}>
                                <i className="toggle_menu ic_hamburger_menu"></i>
                            </div>
                        </>
                        :
                        <>
                            <div className={`sidebarControl left-94`} onClick={() => { props.fixsidebarclickclose(); }}>
                                <i className={`ic_double_arrow_left ${props.fixsidebar === true ? "d-block" : ""}`}></i>
                            </div>
                        </>
                    }
                </div>
                <div className="Navbar">
                    {props.storeHeaderLogo.length > 0 && (props.storeHeaderLogo.map((h, index) => {
                        return (<React.Fragment key={index}>
                            {isEmpty(h.image) !== "" &&
                                (<img className={`${theme == 0 ? "logowhite" : ""} cursor-pointer`}
                                    onClick={(event) => {
                                        sidebarToggle(event)
                                    }}
                                    src={isEmpty(h.image)} alt="logo image1" />)
                            }
                        </React.Fragment>)
                    }))}
                </div>
                <div className="HeaderProfile">
                    {selector.data.access === "Full" &&
                        <div className={`ProfileBar ${selector.storeSectionUrl === "cart" && "active"}`}
                            onClick={(event) => {
                                if (window.location.pathname === "/cart") {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    navigate("/-");
                                }
                                sessionStorage.setItem("storeUrl", "/cart");
                                dispatch(storeSectionUrl("cart"));
                            }}>
                            <Link to={'/cart'} >
                                <i className="ic_cart"></i>
                                {selector.count > 0 &&
                                    <span className="count">{selector.count}</span>}
                            </Link>
                        </div>}

                    <div className={`ProfileBar ${selector.storeSectionUrl === "fav-icon" && "active"}`}
                        onClick={(event) => {
                            if (window.location.pathname === "/favourite") {
                                event.stopPropagation();
                                event.preventDefault();
                                navigate("/-");
                            }
                            sessionStorage.setItem("storeUrl", "/favourite");
                            dispatch(storeSectionUrl("fav-icon"));
                        }}>
                        <Link to={'/favourite'} >
                            <i className={`ic_heart`}></i>
                            {selector.storeFavIconCount > 0 &&
                                <span className="count">{selector.storeFavIconCount}</span>}
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Header;