import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AccessRoute = ({ children }) => {
    const selector = useSelector(state => state)

    // if (isEmpty(selector.data.access) !== "") {
    if (selector.data.access !== "Full") {
        return <Navigate to="/" replace />;
    }
    // }
    return children;
};
export default AccessRoute;