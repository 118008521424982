export const getCountOfCartItems = (data) => {
    return {
        type: "countOfCartItems",
        count: data,
    };
};

export const getLoginDetailsData = (data) => {
    return {
        type: "getLoginDetails",
        data: data,
    };
};

export const storeFavCount = (storeFavCountVal) => {
    return {
        type: "storeFavCount",
        storeFavCount: storeFavCountVal,
    };
};

export const storeEntityId = (storeEntityId) => {
    return {
        type: "storeEntityId",
        storeEntityId: storeEntityId,
    };
};

export const storeSectionUrl = (storeSectionUrl) => {
    return {
        type: "storeSectionUrl",
        storeSectionUrl: storeSectionUrl,
    };
};

export const isJewelDIY = (isJewelDIY) => {
    return {
        type: "isJewelDIY",
        isJewelDIY: isJewelDIY,
    };
};

export const isDiamoDIY = (isDiamoDIY) => {
    return {
        type: "isDiamoDIY",
        isDiamoDIY: isDiamoDIY,
    };
};

export const storeFilterValueName = (storeFilterValueName) => {
    return {
        type: "storeFilterValueName",
        storeFilterValueName: storeFilterValueName,
    };
}

export const storeSubNavbarName = (storeSubNavbarName) => {
    return {
        type: "storeSubNavbarName",
        storeSubNavbarName: storeSubNavbarName,
    };
}

export const isLoginClassAdded = (isLoginClassAdded) => {
    return {
        type: "isLoginClassAdded",
        isLoginClassAdded: isLoginClassAdded,
    };
}

export const storeTempId = (storeTempId) => {
    return {
        type: "storeTempId",
        storeTempId: storeTempId,
    };
}

export const storeCurrency = (storeCurrency) => {
    return {
        type: "storeCurrency",
        storeCurrency: storeCurrency,
    };
}

export const storeHeaderData = (storeHeaderData) => {
    return {
        type: "storeHeaderData",
        storeHeaderData: storeHeaderData,
    };
}

export const setStepperTab = (tabName) => {
    return {
        type: "SET_STEPPER_TAB_NAME",
        payload: tabName,
    };
}

export const handleSingleDiamondAction = (diamondObj) => {
    return {
        type: "ADD_DIAMOND",
        payload: diamondObj
    }
};

export const handleAddDynamicParamsAction = (objData) => {
    return {
        type: "ADD_DYNAMIC_PARAMS",
        payload: objData
    }
};

export const editDiamondAction = (data) => {
    return {
        type: "EDIT_RING_DIAMOND",
        payload: data,
    };
};

export const handleSetActiveTabName = (tabValue) => {
    return {
        type: "SET_ACTIVE_TAB_NAME",
        payload: tabValue
    }
};

export const addEngravingAction = (engravingData) => {
    return {
        type: "ADD_ENGRAVING",
        payload: engravingData
    }
}
