import React from 'react'
import './pageNotFound.scss';
import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoginClassAdded } from '../../Redux/action';

const PageNotFound = () => {

    const selector = useSelector(state => state)
    const dispatch = useDispatch()

    const isLogin = Object.keys(selector.data).length > 0

    useEffect(() => {
        dispatch(isLoginClassAdded(false))
    }, [dispatch])

    return (
        <>
            <section id="error_area" className="ptb-100">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6 mx-auto py-5">
                            <div className="erorr_wrapper text-center py-5">
                                <h3>Whoops, that page is gone.</h3>
                                <div className='fw-bold fs-6'> Go To <Link to={isLogin ? "/dashborad" : "/"} className="gotohome p-2 fw-800 fw-bold fs-6">{isLogin ? "Dashboard" : "Login"}</Link></div>
                                <h1 className='pt-3'>404</h1>
                                <h3>We are sorry, the page you've requested is not available</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageNotFound