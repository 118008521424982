import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthComponent = ({ children }) => {

    const selector = useSelector(state => state)

    const isLoggedIn = Object.keys(selector.data).length > 0

    if (isLoggedIn) {
        return <Navigate to="/dashborad" replace />;
    }
    return children;
};
export default AuthComponent;