import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../../CommanFunctions/commanFunctions";
import "./footer.scss";

const Footer = () => {

    const selector = useSelector(state => state)

    return (
        <footer id="MainFooter" >
            <div className="footer-content">
                <p>© {isEmpty(selector.storeEntityId.store_name)} {new Date().getFullYear()}. Powered by{" "}
                    <span>Upqor Private Limited</span>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
