import { Toast } from "react-bootstrap";
import React from "react";
import "./toast.scss"

const ToastComponent = (props) => {
    return (
        <Toast
            autohide={true}
            onClose={() => props.onClose(false)}
            show={props.show}
            style={{
                top: "7%",
                backgroundColor: props.success ? "#ccf5e1" : "var(--bg-danger)",
                color: props.success ? "#2fac64" : "var(--color-danger)",
                right: "1%",
                zIndex: "9999999",
                width: "500px",
                borderRadius: "5px",
                border: props.success ? "1px solid #2fac64" : "1px solid var(--color-danger)",
                overflow: "hidden"
            }}
            animation={false}
            className={props.className + " position-fixed"}  >
            <Toast.Body>
                <div className="d-flex align-items center">
                    <div className="toster-content" style={{ width: "90%" }}>
                        <div>
                            {/* {props.success ? '<i className="ic_danger me-2"></i>' : '<i className="ic_check_circle me-2"></i>'} */}
                            <i className={`${props.success ? "ic_check_circle" : "ic_danger"}`}></i>
                        </div>
                        {props.toastBody}
                    </div>
                    <button type="button" className="toast-btn ms-auto" aria-label="Close" onClick={() => props.onClose(false)} data-dismiss="toast"
                        style={{
                            fontSize: "18px",
                            backgroundImage: 'none'
                        }}
                        
                    > <i className={`${props.success ? "ic_close" : "ic_close"}`}
                    style={{
                        color: props.success ? "#2fac64" : "var(--color-danger)",
                    }}
                    ></i></button>
                </div>
            </Toast.Body>
            <div className="w-1" style={{
                backgroundColor: props.success ? "#2fac64" : "var(--color-danger)",
            }}></div>
        </Toast>
    );
}

export default ToastComponent