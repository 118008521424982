import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isLoginClassAdded } from "../../Redux/action";

const Protected = ({ children }) => {

    const selector = useSelector(state => state)
    const dispatch = useDispatch()

    const isLoggedIn = Object.keys(selector.data).length > 0

    useEffect(() => {
        dispatch(isLoginClassAdded(true))
    }, [dispatch])

    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }
    return children;
};

export default Protected;