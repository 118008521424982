import React from "react";
import { lazyWithRetry } from "../../Components/CommanFunctions/commanFunctions";
import AccessRoute from "../AccessRoute/accessRoute";

const CouponCode = lazyWithRetry(() => import("../../Components/Profile/CouponCode/couponCode"))
const Refresh = lazyWithRetry(() => import("../../CommanUIComp/Refresh/refresh"));
const Login = lazyWithRetry(() => import("../../Components/Profile/SignInAndForgotPassword/signInAndForgotPassword"));
const Dashboard = lazyWithRetry(() => import("../../Components/Dashboard/dashboard"));
const Jewellery = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithSetting/StartWithSetting/StartWithSetting/startWithSetting"));
const Cart = lazyWithRetry(() => import("../../Components/Cart/Cart/cart"));
const Diamond = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithDiamond/SearchList/searchList"));
const Profile = lazyWithRetry(() => import("../../Components/Profile/Profile/profile"));
const SingleProductSetting = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithSetting/ProductDetailsPreview/singleProductSetting"));
const SingleProductDiamond = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithDiamond/ProductDetailsPreview/singleProductDiamond"));
const Favourite = lazyWithRetry(() => import("../../Components/Favourite/favourite"));
const SuccessOrder = lazyWithRetry(() => import("../../Components/Cart/SuccessOrder/successOrder"));
const OrderManagement = lazyWithRetry(() => import("../../Components/OrderManagement/OrderB2C/orderB2C"));
const Orderdetails = lazyWithRetry(() => import("../../Components/OrderManagement/OrderDetailsB2C/orderDetailsB2C"));
const LooseDiamond = lazyWithRetry(() => import("../../Components/Product/ProductB/StartWithLoose/startWithLoose"));
const Inventory = lazyWithRetry(() => import("../../Components/Inventory/inventory"));
const InventoryOld = lazyWithRetry(() => import("../../Components/InventoryOld/inventory"));
const Shipment = lazyWithRetry(() => import("../../Components/Shipment/shipment"));
const SalesInvoice = lazyWithRetry(() => import("../../Components/SalesInvoice/salesinvoice"));
const MyTeam = lazyWithRetry(() => import("../../Components/MyTeam/myteam"));
const ConsumerList = lazyWithRetry(() => import("../../Components/ConsumerList/Consumer"));
const SampleList = lazyWithRetry(() => import("../../Components/Icon/icon"));
const PaymentComponent = lazyWithRetry(() => import("../../Components/Payment/payment"));
const StartWithDiamondSelection = lazyWithRetry(() => import("../../Components/DesignYourOwnRing/StartWithDiamondSelection/StartWithDiamondSelection"));
const SingleDiamondItem = lazyWithRetry(() => import("../../Components/DesignYourOwnRing/SingleDiamondStone/SingleDiamondItem"));
const DiamondRingList = lazyWithRetry(() => import("../../Components/DesignYourOwnRing/DiamondRing/DiamondRingList"));
const SingleDiamondJewelleryItem = lazyWithRetry(() => import("../../Components/DesignYourOwnRing/SingleDiamondJewelleryItem/SingleDiamondJewelleryItem"));
const CampaignPreview = lazyWithRetry(() => import('../../Components/CampaignPreview/campaignPreview'));
const CampaignInquiry = lazyWithRetry(() => import('../../Components/CampaignInquiry/CampaignInquiry'));

export const RouteComponent = [
	{
		path: "/dashborad",
		component: Dashboard,
		element: <Dashboard />
	},
	{
		path: "/-",
		component: Refresh,
		element: <Refresh />
	},
	{
		path: "/profile",
		component: Profile,
		element: <Profile />
	},
	{
		path: "/products/:verticalCode",
		component: Jewellery,
		element: <Jewellery />
	},
	{
		path: "/products/:verticalCode/:variantId",
		component: SingleProductSetting,
		element: <SingleProductSetting />
	},
	{
		path: "/products/:verticalCode/:productKey/:value",
		component: Jewellery,
		element: <Jewellery />
	},
	{
		path: "/products/:verticalCode/:productKey/:value/:variantId",
		component: SingleProductSetting,
		element: <SingleProductSetting />
	},
	{
		path: "/jewellery/start-with-a-setting",
		component: Jewellery,
		element: <Jewellery />
	},
	{
		path: "/jewellery/start-with-a-setting/:variantId",
		component: SingleProductSetting,
		element: <SingleProductSetting />
	},
	{
		path: "/jewellery/start-with-a-diamond",
		component: Jewellery,
		element: <Jewellery />
	},
	// {
	// 	path: "/frame",
	// 	component: Jewellery,
	// 	element: <Jewellery />
	// },
	// {
	// 	path: "/frame/:productKey/:value/",
	// 	component: Jewellery,
	// 	element: <Jewellery />
	// },
	// {
	// 	path: "/frame/:productKey/:value/:variantId",
	// 	component: SingleProductSetting,
	// 	element: <SingleProductSetting />
	// },
	{
		path: "/certificate-diamond",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/certificate-diamond/:shape/:item",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/certificate-diamond/:shape/:item/:certificateNo",
		component: SingleProductDiamond,
		element: <SingleProductDiamond />
	},
	{
		path: "/certificate-diamond/start-with-a-diamond",
		component: Jewellery,
		element: <Jewellery />
	},
	{
		path: "/certificate-diamond/start-with-a-setting",
		component: Jewellery,
		element: <Jewellery />
	},
	{
		path: "/lab-grown-diamond/:shape/:item",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/lab-grown-diamond/:shape/:item/:certificateNo",
		component: SingleProductDiamond,
		element: <SingleProductDiamond />
	},
	{
		path: "/lab-grown-diamond",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/lab-grown-gemstone/:shape/:item",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/lab-grown-gemstone/:shape/:item/:certificateNo",
		component: SingleProductDiamond,
		element: <SingleProductDiamond />
	},
	{
		path: "/lab-grown-gemstone",
		component: Diamond,
		element: <Diamond />
	},
	{
		path: "/loose-diamond",
		component: LooseDiamond,
		element: <LooseDiamond />
	},
	{
		path: "/loose-diamond/:shapeName/:shape",
		component: LooseDiamond,
		element: <LooseDiamond />
	},
	{
		path: "/gemstone-diamond",
		component: LooseDiamond,
		element: <LooseDiamond />
	},
	{
		path: "/gemstone-diamond/:shapeName/:shape",
		component: LooseDiamond,
		element: <LooseDiamond />
	},
	{
		path: "/success-order/:succesOrderId",
		component: SuccessOrder,
		element: <AccessRoute> <SuccessOrder /></AccessRoute>
	},
	{
		path: "/success-order/:succesOrderId/:orderAmnt",
		component: SuccessOrder,
		element: <AccessRoute> <SuccessOrder /></AccessRoute>
	},
	{
		path: "/cancel-order/:succesOrderId",
		component: SuccessOrder,
		element: <AccessRoute> <SuccessOrder /></AccessRoute>
	},
	{
		path: "/cart",
		component: Cart,
		element: <AccessRoute> <Cart /> </AccessRoute>
	},
	{
		path: "/favourite",
		component: Favourite,
		element: <Favourite />
	},
	{
		path: "/order-management",
		component: OrderManagement,
		element: <AccessRoute> <OrderManagement /> </AccessRoute>
	},
	{
		path: "/order-details/:orderId",
		component: Orderdetails,
		element: <AccessRoute> <Orderdetails /> </AccessRoute>
	},
	{
		path: "/coupon-code",
		component: CouponCode,
		element: <CouponCode />
	},
	{
		path: "/inventory",
		component: Inventory,
		element: <Inventory />
	},
	{
		path: "/inventory-old",
		component: InventoryOld,
		element: <InventoryOld />
	},
	{
		path: "/shipment",
		component: Shipment,
		element: <Shipment />
	},
	{
		path: "/sales-invoice",
		component: SalesInvoice,
		element: <SalesInvoice />
	},
	{
		path: "/my-team",
		component: MyTeam,
		element: <MyTeam />
	},
	{
		path: "/consumerlist",
		component: ConsumerList,
		element: <ConsumerList />
	},
	{
		path: "/sample",
		component: SampleList,
		element: <SampleList />
	},
	{
		path: "/payment",
		component: PaymentComponent,
		element: <PaymentComponent />
	},
	{
		path: "/start-with-diamond",
		component: StartWithDiamondSelection,
		element: <StartWithDiamondSelection />
	},
	{
		path: "/start-with-diamond/:shape",
		component: SingleDiamondItem,
		element: <SingleDiamondItem />
	},
	{
		path: "/start-with-diamond/jewellery/diamond-rings",
		component: DiamondRingList,
		element: <DiamondRingList />
	},
	{
		path: "/start-with-diamond/jewellery/diamond-rings/:id",
		component: SingleDiamondJewelleryItem,
		element: <SingleDiamondJewelleryItem />
	},
	{
		path: "/inquiry",
		component: CampaignInquiry,
		element: <CampaignInquiry />
	},

];

export const authRoute = [
	{
		path: "/",
		component: Login,
		element: <Login />,
	},
	{
		path: "/-",
		component: Refresh,
		element: <Refresh />
	},
	{
		path: "/campaign/:campaign_id?/:email?/:un_id?/:name?",
		component: CampaignPreview,
		element: <CampaignPreview />
	},
];
